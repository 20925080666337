import { Stack,Text, TextInput,Flex, ActionIcon, Paper, SimpleGrid } from '@mantine/core'
import { IconMinus, IconPlus } from '@tabler/icons-react'
import { useState,useEffect} from 'react'

export default function LocationBoard(props) {
    const [locInput, setLocInput] = useState("");
    const [locations,setLocations] = useState([]);
    console.log(props)
    useEffect(()=> { props.onChange(locations)},[locations])
    return (
        <>
            <Text >Lieux</Text>
            <SimpleGrid cols={3}>
            {locations.map((loc,index)=> <LocationRow loc={loc} index={index} key={loc}/>)}
                <Flex m={"xs"}>
                    <TextInput value={locInput} onChange={(e)=>setLocInput(e.currentTarget.value)}/>
                    <ActionIcon component={IconPlus} onClick={()=> setLocations([...locations,locInput])}/>
                </Flex>
            </SimpleGrid>
        </>
    )
    function LocationRow({loc,index}){
        return <div>
                    <Paper my={10} p={3}>
                        <Flex direction={"row-reverse"} gap={"md"}>
                        <ActionIcon component={IconMinus} onClick={()=> setLocations(locations.slice(0,index).concat(locations.slice(index+1)))}/>
                        <Text>{loc}</Text>
                        </Flex>
                    </Paper>
                </div>
    }
}



