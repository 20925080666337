import { Avatar, Text, Button, Paper, Anchor,Badge, Stack } from '@mantine/core';
import MemberBadge from './component/MemberBadge';

function formatedPhone(phone) {
    return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 10)}`
}

export function MemberCard(props) {
    const { prenom, nom, email, phone,type,idMembre } = props.props;

    return (
        <Paper
            radius="md"
            withBorder
            p="lg"
            sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
            })}
        >
            <Avatar size={120} radius={120} mx="auto" />
            <Text ta="center" size="lg" weight={500} mt="md">
                {`${prenom} ${nom}`}
            </Text>
            <Stack align='center'>
            <MemberBadge type={type} />
            </Stack>
            <Text ta="center" color='grey'>{idMembre}</Text>
            <Anchor href={`mailto:${email}`}>
                <Text  ta="center" >
                    Courriel : {email ? email : "aucun courriel au dossier"}
                </Text>
            </Anchor>
            <Text ta="center">
                Téléphone : {phone ? formatedPhone(phone) : "aucun numéro au dossier"}
            </Text>
            <Button variant="default" fullWidth mt="md">
                Contacter
            </Button>
        </Paper>
    );
}