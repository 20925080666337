import { createStyles, Select, TextInput,  Button, Container, Stack, Text, Card,Flex,Modal, Radio } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import {DatePicker} from "@mantine/dates";
import 'dayjs/locale/fr-ca';
import { useForm } from '@mantine/form';
import { createOnePayment,searchMember, getUser } from '../connections/requests';
import { useState } from 'react';
import {useQuery} from "react-query";
const useStyles = createStyles((theme) => ({
    root: {
        position: 'relative',
    },

    input: {
        height: 'auto',
        paddingTop: 18,
    },

    label: {
        position: 'absolute',
        pointerEvents: 'none',
        fontSize: theme.fontSizes.xs,
        paddingLeft: theme.spacing.sm,
        paddingTop: theme.spacing.sm / 2,
        zIndex: 1,
    },
}));



export function AddGrantForm() {
    const { classes } = useStyles();
    const [search, setSearch] = useState("");
    const [opened, setOpened] = useState(false);
    const [debouncedSearch] = useDebouncedValue(search, 200)
    const { data, status } = useQuery(['members', debouncedSearch], searchMember);
    const form = useForm(
        { initialValues: 
        { member:"",worker: getUser(),event:undefined, amount: 0, type: "",description:"",status:"waiting",timeStamp:new Date()}
        });
    return (
        <form onSubmit={form.onSubmit((values) => {createOnePayment(values).then(form.reset)})}>
                <Modal opened={opened} onClose={() => setOpened(false)} title="Recherche Membre">
                    <TextInput
                        label="Inscrire le nom ou prénom"
                        value={search}
                        style={{ flex: 1 }}
                        onChange={(event) => setSearch(event.currentTarget.value)}
                    />
                    {(status === "success") ? data.map(i => <Button color="dark" m={"xl"} key={i._id} onClick={(e) => { form.setValues({ member: i._id,memberName:i.prenom + " " + i.nom }); setOpened(false) }}><Text>{`${i.prenom} ${i.nom}`}</Text></Button>) : <Text my={"sm"} ta={"center"}>vide </Text>}
                </Modal>
        <Container py={35} px={50}>
            <Stack align='center'>
                <Card mb={34} w={"14rem"}  >
                    <Text align='center' size={"xl"} weight={700}>Ajouter un paiement</Text>
                </Card>
            </Stack>
            <Stack>
                <Flex align={"center"} gap={"lg"}>
                <TextInput  placeholder="" classNames={classes} {...form.getInputProps('partner')} />
                </Flex>

                <TextInput label="Montant" classNames={classes} {...form.getInputProps('amount')} />
                <TextInput label="Description et notes" className={classes} {...form.getInputProps("description")}/>
                <Select
                    required
                    style={{ marginTop: 20, zIndex: 2 }}
                    data={['Subvention']}
                    placeholder="..."
                    label="Type de paiement"
                    classNames={classes}
                    {...form.getInputProps('type')}
                />
                <Radio.Group name="status"  label="Status"
                    {...form.getInputProps('status')}>
                <Radio color='orange' value="waiting" label="En Attente"/>
                <Radio color='green' value="received" label="Reçu"/>
                </Radio.Group>
                <DatePicker
                    inputFormat='DD MMMM YYYY'
                    labelFormat='DD/MMMM/YYYY'
                    locale='fr-ca'
                    label="Date"
                    classNames={classes}
                    allowFreeInput
                    clearable={false}
                    {...form.getInputProps('timeStamp')}
                />
                <Button type='submit'>Soumettre</Button>
            </Stack>
        </Container >
        </form>
    );
}