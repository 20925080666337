import React from 'react'
import {Card, Center,SimpleGrid,Stack,Title,Loader} from "@mantine/core";
import { useQuery } from 'react-query'
import { fetchJobsDayByWorkerID } from '../connections/requests'
import DayCard from './DayCard'
import CreateDayJob from './CreateDayJob';

const mockdata = [
    {date:new Date(2024,10,10),hours:[[new Date(2024,10,10,8,30),new Date(2024,10,10,10,30)],[new Date(2024,10,10,13,30),new Date(2024,10,10,17)]]},
    {date:new Date(2024,10,11),hours:[[new Date(2024,10,10,8,30),new Date(2024,10,10,10,30)]]}
]

export default function DayBoard({workerID}) {
   const dayQuery = useQuery(['day',workerID],fetchJobsDayByWorkerID)
    return (
        <Card m={"lg"} >
            <Card.Section bg={"blue"}>
                <Title c={"white"} order={3} ta={"center"}>DayBoard</Title>
            </Card.Section>
            <Card.Section>
                <CreateDayJob/>
            </Card.Section>
            <SimpleGrid cols={5}>
                {dayQuery.status==="success" ? dayQuery.data.map(e => <DayCard key={e.date} date={e.date} hours={e.hours} />):<Loader/>}
            </SimpleGrid>
        </Card>
    )
}
