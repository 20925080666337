import {
    Badge,
    Table,
    Group,
    Text,
    Anchor,
    ScrollArea,
    createStyles,
    UnstyledButton,
    Container,
    Card,
    Title
} from '@mantine/core';
import {IconUserPlus} from '@tabler/icons-react';
import { useQuery } from 'react-query';
import { Link } from "react-router-dom";
import { fetchAllWorkers } from '../connections/requests';

const jobColors = {
    Inter: 'blue',
    Benevole: 'cyan',
    Admin: 'indigo',
    Collab: "grape"
};
const jobTranslate = {
    Benevole: 'Bénévole',
    Inter: 'Intervenant',
    Collab:"Collaborateur",
    Admin:"Administrateur"
}

const useStyles = createStyles((theme) => ({
    card: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontWeight: 700,
    },

    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        borderRadius: theme.radius.md,
        height: 90,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
        transition: 'box-shadow 150ms ease, transform 100ms ease',

        '&:hover': {
            boxShadow: `${theme.shadows.md} !important`,
            transform: 'scale(1.05)',
        },
    },
}));


export default function TeamPage() {
    const { classes, theme } = useStyles();
    const workerQuery = useQuery('workers', fetchAllWorkers)
    const rows = workerQuery.status === "success" && workerQuery.data.map((item) => (
            <tr key={item._id}>
                <td>
                    <Group spacing="sm">
                        <Text component={Link} to={ item._id} size="sm" weight={500}>
                            {item.prenom? item.prenom : "aucun prénom"} {item.nom ? item.nom : "aucun nom"}
                        </Text>
                    </Group>
                </td>

                <td>
                    {item.rank ?
                    <Badge
                        color={jobColors[item.rank.toLowerCase()]}
                        variant={theme.colorScheme === 'dark' ? 'light' : 'outline'}
                    >
                        {jobTranslate[item.rank]}
                    </Badge>
                    : <Badge color='red' variant='outline'>Erreur</Badge>
                    }
                </td>
                <td>
                    <Anchor size="sm" href={`mailto:${item.email}`} >
                        {item.email}
                    </Anchor>
                </td>
                <td>
                    <Text size="sm" color="dimmed">
                        {item.phone}
                    </Text>
                </td>
                <td>

                </td>
            </tr>
    ));

    return (
        <Container my={"lg"}>
            <Card withBorder p={"lg"}>
                <Card.Section p={"md"} withBorder bg={theme.colors.blue}> <Title ta={"center"} color='white' order={2}>List des intervenants</Title></Card.Section>
                <UnstyledButton className={classes.item}>
                    <Link to={"/auth/form/addTeam"} variant="text">
                        <IconUserPlus color={theme.colors["green"][6]} size={32} />
                        <Text size="xs" mt={7} color={"green"}>
                            Ajouter un intervenant
                        </Text>
                    </Link>
                </UnstyledButton>
                <ScrollArea>
                    <Table sx={{ minWidth: 800 }} verticalSpacing="sm">
                        <thead>
                            <tr>
                                <th>Travailleur</th>
                                <th>Titre</th>
                                <th>Courriel</th>
                                <th>Telephone</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                    </Table>
                </ScrollArea>
            </Card>
        </Container>
    );
}