import { createStyles, Card, Text, SimpleGrid, UnstyledButton,  Center, Title, Container } from '@mantine/core';
import { Link,Routes,Route } from "react-router-dom";
import {
    IconCalendarPlus,
    IconUserPlus,
    IconRoad,
    IconCashBanknote,
    IconFileDollar
} from '@tabler/icons-react';
import { AddMemberForm } from '../forms/addmember';
import { AddTeamForm } from '../forms/AddTeam';
import { AddEventForm } from '../forms/addevent';
import { AddPaymentForm } from '../forms/AddPayment';
import { AddRunForm } from '../forms/AddRun';
import { AddGrantForm } from '../forms/AddGrant';
import { AddTimeslotForm } from '../forms/AddTimeslot';

const mockdata = [
    { title: 'Ajouter un membre', icon: IconUserPlus, color: 'blue', href: "addMember" },
    { title: 'Ajouter un événement', icon: IconCalendarPlus, color: 'indigo', href: "addEvent" },
    {title:'Ajouter un paiement',icon:IconCashBanknote, color:'green', href:"addPayment"},
    {title:'Ajouter une course',icon:IconRoad, color:'orange', href:"addRun"},
    {title:"Ajouter une subvention",icon:IconFileDollar,color:'violet',href:"addGrant"}

];

const useStyles = createStyles((theme) => ({
    card: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontWeight: 700,
    },

    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        borderRadius: theme.radius.md,
        height: 90,
        transition: 'box-shadow 150ms ease, transform 100ms ease',

        '&:hover': {
            transform: 'scale(1.05)',
        },
    },
}));
export function FormPage(){
    return(
    <Routes>
        <Route path='' element={<MainPage/>}/>
        <Route path="/addMember" element={<AddMemberForm/>}/>
        <Route path="/addTeam" element={<AddTeamForm/>}/>
        <Route path="/addEvent" element={<AddEventForm/>}/>
        <Route path="/addPayment" element={<AddPaymentForm/>}/>
        <Route path="/addGrant" element={<AddGrantForm/>}/>
        <Route path='/addRun' element={<AddRunForm/>}/>
        <Route path='/addTime' element={<AddTimeslotForm/>}/>
    </Routes>)
}

function MainPage() {
    const { classes, theme } = useStyles();

    const items = mockdata.map((item) => (
        <Card withBorder radius={'md'} className={classes.card}>
        <Center>
        <UnstyledButton key={item.title} className={classes.item} color={item.color}>
            <Link to={item.href} variant="text">
                <item.icon color={theme.colors[item.color][6]} size={32} />
                <Text size="xs" mt={7} color={"light"}>
                    {item.title}
                </Text>
            </Link>
        </UnstyledButton>
        </Center>
        </Card>
    ));

    return (
        <Container>
        <Card withBorder radius="md" className={classes.card}>
                <Title ta={"center"} order={1} className={classes.title}>Services</Title>
        </Card>
            <SimpleGrid cols={3} mt="md">
                {items}
            </SimpleGrid>
        </Container>

    );
}