import React,{useState} from 'react'
import {Button, Flex, Title,Modal, Center, Stack} from "@mantine/core"
import { useDisclosure } from '@mantine/hooks'
import { useForm } from '@mantine/form'
import { Calendar, DatePicker } from '@mantine/dates'
import DayCard from './DayCard'
import { getUser,createJobDay } from '../connections/requests'

export default function CreateDayJob() {
    const [opened,handlers] = useDisclosure(false)
    const form  = useForm({initialValues:{hours:[],worker:getUser(),type:"day",date: undefined}})
    console.log(form.values?.hours)
    return (
        <>
        <Modal opened={opened}
        onClose={()=> {handlers.close();form.reset()}}
        withCloseButton={false}
        size={"sm"}
        >
        <form>
                {form.values?.date === undefined ? (<Center>
                <Stack>
                        <Title ta={"center"} order={5}>Choisir une journée</Title>
                            <Calendar m={"md"} {...form.getInputProps("date")}/>
                        </Stack>
                    </Center> ):  (
                    <Center>
                        <Stack>
                            <Title ta={"center"} order={5}>Ajouter des heures</Title>  
                            <DayCard mode={"component"} date={form.values?.date} hours={[]} {...form.getInputProps("hours")}/>
                            <Button color='grape' onClick={()=>createJobDay(form.values)}>Ajouter</Button>
                        </Stack>
                    </Center>)
                    }

        </form>
        </Modal>
        <Flex m={"md"}>
            <Title order={2} ta={"center"}></Title>
            <Button onClick={()=> handlers.toggle()} color={"grape"}>Ajouter une cédule</Button>
        </Flex>
        </>
        
    )
}
