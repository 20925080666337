import React, { useState } from 'react'
import { Button, Container, Flex, Grid, Modal } from '@mantine/core';
import { Chrono } from '../member/chrono';
import { useQuery } from 'react-query';
import {  useNavigate, useParams } from 'react-router-dom';
import { WorkerInfo } from './workerInfo';
import {deleteWorkerByID,fetchWorker } from '../connections/requests';
import { TeamCard } from './components/TeamCard';
import WorkerInfoEdit from './WorkerInfoEdit';
import ScheduleTable from './ScheduleTable';
import DayCard from './DayCard';
import DayBoard from './DayBoard';


export default function WorkerPage() {
    let redirect = useNavigate();
    let { worker } = useParams();
    const [openedChrono, setOpenedChrono] = useState(false);
    const [openedDelete, setOpenedDelete] = useState(false);
    const [openedEdit, setOpenedEdit] = useState(false);
    const { data, status } = useQuery(['workers', worker], fetchWorker);
    if (status === "success")
        return (
            <Container size={"lg"} my="md">
                <Flex my={"md"} gap={"md"} justify={"flex-start"}>
                    <Button color='orange' onClick={() => setOpenedChrono(true)}>Ajouter Chrono</Button>
                    <Button color='red' onClick={() => setOpenedDelete(true)}>Supprimer l'utilisateur</Button>
                    <Button color='green' onClick={() => setOpenedEdit(true)}>Modifier l'utilisateur</Button>
                </Flex>
                <Modal
                    opened={openedChrono}
                    onClose={() => setOpenedChrono(false)}
                    size="75%"
                >
                    <Chrono memberId={worker} modal={setOpenedChrono} />
                </Modal>

                <Modal opened={openedDelete}
                    onClose={() => setOpenedDelete(false)}
                >
                    <Button onClick={()=> {deleteWorkerByID(worker);redirect("/auth/team")}}>Confirmer la suppression</Button>
                </Modal>
                <Modal opened={openedEdit}
                    onClose={()=>setOpenedEdit(false)}
                    >
                    <WorkerInfoEdit worker={data}/>
                    </Modal>
                <Grid gutter="md">
                    <Grid.Col span={6}>
                        <TeamCard props={data} />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <WorkerInfo props={data} />
                    </Grid.Col>
                    <Grid span={12}>
                        <DayBoard workerID={worker}/>
                    </Grid>
                </Grid>
            </Container>
        );
}
