import React from 'react';
import { Text, createStyles,  Divider, } from '@mantine/core';
import {
  Link, useHref
} from "react-router-dom"


const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
  },
  IconUser: {
    alignContent: 'right'
  },
  input: {
    height: 'auto',
    paddingTop: 18,
  },
  divider: {
    width: '78%'
  },
  link: {
    justifyContent: "center",
    alignContent: "center",
    display: "flex",
    color: "white"

  },
  active: {
    justifyContent: "center",
    alignContent: "center",
    display: "flex",
    color: "orange"
  }
  ,
  label: {
    position: 'absolute',
    pointerEvents: 'none',
    fontSize: theme.fontSizes.xs,
    paddingLeft: theme.spacing.sm,
    paddingTop: theme.spacing.sm / 2,
    zIndex: 1,
  },
}));

function checkURL(url, href) {
  function checkLast() {
    if (url.indexOf('/', 2) < 0) return url.length
    else return url.indexOf('/', 2)
  }
  const croppedURL = url.substring(url.indexOf('/'), checkLast())
  if (croppedURL === href) return true;
  else return false


}
function NavButton(props) {
  const { classes, cx } = useStyles();
  const url = useHref();
  checkURL(url, props.href)
  return (<div className={classes.divider} >
    <Divider my={"xs"} color="white" size={"xs"} />
    <Link className={classes.link} to={"/auth/"+props.href} style={{ textDecoration: 'none' }}> <Text tt={"uppercase"} className={cx(classes.link, { [classes.active]: checkURL(url, props.href) })} weight={600} >{props.name}</Text> </Link>
  </div >
  );
}
export default NavButton;